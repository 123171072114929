import * as React from 'react';

import type { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { CtaBlock } from 'stile-us/src/components/blocks/CtaBlock';

import { HeroBlock } from 'stile-shared/src/components/2020/blocks/HeroBlock';
import { TeaserBlock } from 'stile-shared/src/components/2020/blocks/TeaserBlock';
import { TextBlock } from 'stile-shared/src/components/2020/blocks/TextBlock';
import { TimelineBlock } from 'stile-shared/src/components/2020/blocks/TimelineBlock';
import { ButtonLink } from 'stile-shared/src/components/2020/common/ButtonLink';
import { Link } from 'stile-shared/src/components/2020/common/Link';
import { P } from 'stile-shared/src/components/2020/common/Primitives';
import { CONTACT } from 'stile-shared/src/constants';

import { PageLayout } from 'templates/2020/PageLayout';

import { NextPageBlock } from 'components/blocks/NextPageBlock';

export { Head } from 'templates/2020/Head';

function HomePage(props: PageProps) {
  return (
    <PageLayout {...props}>
      <HeroBlock
        title="The perfect pilot"
        image={
          <StaticImage
            loading="eager"
            aspectRatio={2.37}
            src="../../../../stile-shared/assets/images/nevada-oregon-homepages/cover.png"
            alt="A group of students performing a lab experiement in a classroom filled with plants"
          />
        }
      />

      <TextBlock>
        <P>
          We believe it’s crucial to thoroughly evaluate every teaching resource. That’s why we’ve
          designed a pilot process that covers everything from unit introduction to final
          assessment, combining digital and hands-on methods for a complete learning experience.
        </P>

        <P>
          Leaders and teachers also get the chance to explore what a true partnership with Stile
          will be like, including professional learning and the chance to try out Stile X with all
          of your students.
        </P>
      </TextBlock>

      <TimelineBlock
        title="Example pilot timeline"
        variant="dark"
        content={[
          {
            titleLines: ['Leader Kick-off | 90 mins'],
            mainContent:
              "Gear up and hit the road with Oregon's new core science curriculum! Together, we'll design a pilot that is customized to the unique needs of your district.",
          },
          {
            titleLines: ['Teacher Kick-off | 90 mins'],
            mainContent:
              'First stop is getting teachers ready to teach a unit of their choice, facilitating a deep-dive into our many teacher resources, and getting them ready to teach their very first lesson with Stile.',
          },
          {
            titleLines: ['Leader pit stop #1'],
            mainContent:
              "Leaders receive a quick update on what their students will be learning over the Stile pilot and get an instructional tool to measure the impact of Stile in their teachers' classrooms.",
          },
          {
            titleLines: ['Teacher Milestone #1'],
            mainContent:
              'During this session, teachers learn more about the Stile features they have encountered while teaching with Stile in their classroom. This is a great time for teachers to ask questions and learn alongside their science colleagues!',
          },
          {
            titleLines: ['Leader pit stop #2'],
            mainContent:
              "Leaders check in with their Stilist as we share teacher and student stories, and the data we've collected so far.",
          },
          {
            titleLines: ['Teacher Milestone #2'],
            mainContent:
              'Finally, teachers get a taste of Stile professional learning. Building on initial Stile training, districts may choose from a variety of learning opportunities such as sessions on 3D Learning, Assessment, Modeling, and more.',
          },
          {
            titleLines: ['Final Destination'],
            mainContent:
              'This is your pilot, but we also love to hear about how it went and how we can make our curriculum even better for your schools. Sit down with us to not only share your feedback, but also to discuss a customized implementation experience to suit the needs of your district.',
          },
        ]}
      />

      <TeaserBlock
        title="Hear what teachers say"
        variant="beige"
        video={{
          text: 'Hear from Abby, Jen, Stephen and Jim (4 mins)',
          url: 'https://www.youtube.com/watch?v=7YXQYLq-6tU',
          coverImage: (
            <StaticImage
              // TODO: replace image
              src="../../../../stile-shared/assets/images/oregon-pilot/teacher_video_cover.png"
              alt=""
            />
          ),
        }}
      >
        <P>
          Don’t take it from us! Learn what Abby, Jenn, Stephen, and Jim have to say about Stile.
        </P>
      </TeaserBlock>

      <NextPageBlock path="/other/resources/"></NextPageBlock>

      <CtaBlock />
    </PageLayout>
  );
}

export default HomePage;
