import * as React from 'react';

import styled from 'styled-components';

import { theme } from '../../../../styles/2020/theme';

import { H2, P } from '../../common/Primitives';
import { Center } from '../../layout/Center';
import { FullWidthBackground } from '../../layout/FullWidthBackground';
import { Media } from '../../layout/Media';

type TimelineContent = { titleLines: string[]; mainContent: string };

type TimelineBlockProps = {
  title?: string;
  variant?: 'dark' | 'beige';
  content: TimelineContent[];
};

export function TimelineBlock({ title, variant, content }: TimelineBlockProps) {
  return (
    <FullWidthBackground
      forwardedAs="section"
      invertColors={variant === 'dark'}
      secondaryColor={variant === 'beige' ? theme.colors.beige : theme.colors.white}
    >
      {title && <H2>{title}</H2>}

      <Media greaterThanOrEqual="s">
        <Timeline content={content} alternating />
      </Media>

      <Media lessThan="s">
        <Timeline content={content} />
      </Media>
    </FullWidthBackground>
  );
}

type TimelineProps = { alternating?: boolean; content: TimelineContent[] };

function Timeline({ alternating, content }: TimelineProps) {
  if (alternating) {
    return (
      <Center max={theme.space.siteWidth} stretch style={{ margin: '20px 0' }}>
        {content.map((item, index) => {
          if (index + 1 !== content.length) {
            return (
              <TimelineCenterCard
                key={index}
                flip={!(index % 2)}
                titleLines={item.titleLines}
                text={item.mainContent}
              />
            );
          }
        })}

        <TimelineFinalCard
          titleLines={content[content.length - 1].titleLines}
          text={content[content.length - 1].mainContent}
        />
      </Center>
    );
  } else {
    return (
      <Center>
        {content.map((item, index) => {
          if (index + 1 !== content.length) {
            return (
              <TimelineSideCard key={index} titleLines={item.titleLines} text={item.mainContent} />
            );
          }
        })}

        <TimelineFinalCard
          titleLines={content[content.length - 1].titleLines}
          text={content[content.length - 1].mainContent}
          narrowVariant
        />
      </Center>
    );
  }
}

type TimelineCardBaseProps = {
  titleLines: string[];
  text: string;
};

type TimelineCenterCardProps = TimelineCardBaseProps & { flip?: boolean };

function TimelineCenterCard({ titleLines, text, flip }: TimelineCenterCardProps) {
  if (flip) {
    return (
      <ContentContainer>
        <TextContainer align="right">
          {titleLines.length !== 0 &&
            titleLines.map((line, index) => (
              <P bold smallCaps color="green2" key={index}>
                {line}
              </P>
            ))}
          <P>{text}</P>
        </TextContainer>

        <TimelineLineSection />

        <TextContainer />
      </ContentContainer>
    );
  } else {
    return (
      <ContentContainer>
        <TextContainer />

        <TimelineLineSection />

        <TextContainer>
          {titleLines.length !== 0 &&
            titleLines.map((line, index) => (
              <P bold smallCaps color="green2" key={index}>
                {line}
              </P>
            ))}
          <P>{text}</P>
        </TextContainer>
      </ContentContainer>
    );
  }
}

type TimelineSideCardProps = TimelineCardBaseProps;

function TimelineSideCard({ titleLines, text }: TimelineSideCardProps) {
  return (
    <ContentContainer>
      <TimelineLineSection />
      <TextContainer>
        {titleLines.length !== 0 &&
          titleLines.map((line, index) => (
            <P bold smallCaps color="green2" key={index}>
              {line}
            </P>
          ))}
        <P>{text}</P>
      </TextContainer>
    </ContentContainer>
  );
}

type TimelineFinalCardProps = TimelineCardBaseProps & { narrowVariant?: boolean };

function TimelineFinalCard({ titleLines, text, narrowVariant }: TimelineFinalCardProps) {
  if (narrowVariant) {
    return (
      <ContentContainer column>
        <NarrowScreenFinalLineSegment />
        <TextContainer border align="center">
          {titleLines.length !== 0 &&
            titleLines.map((line, index) => (
              <P bold smallCaps color="green2" key={index}>
                {line}
              </P>
            ))}
          <P>{text}</P>
        </TextContainer>
      </ContentContainer>
    );
  }

  return (
    <ContentContainer>
      <TextContainer border align="center">
        {titleLines.length !== 0 &&
          titleLines.map((line, index) => (
            <P bold smallCaps color="green2" key={index}>
              {line}
            </P>
          ))}
        <P>{text}</P>
      </TextContainer>
    </ContentContainer>
  );
}

function TimelineLineSection() {
  return (
    <TimelineLineSectionContainer>
      <TimelineDot />
      <TimelineCenteredLine />
    </TimelineLineSectionContainer>
  );
}

const TimelineLineSectionContainer = styled.div`
  flex: 0 0 24px;
  position: relative;
`;

const TimelineDot = styled.div`
  height: 24px;
  width: 24px;
  background-color: ${theme.colors.green1};
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
`;

const TimelineCenteredLine = styled.div`
  background-color: ${theme.colors.green1};
  width: 4px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
`;

function NarrowScreenFinalLineSegment() {
  return (
    <NarrowScreenFinalLineSegmentContainer>
      <NarrowScreenFinalLineSegmentLeft />
      <NarrowScreenFinalLineSegmentRight />
    </NarrowScreenFinalLineSegmentContainer>
  );
}

const NarrowScreenFinalLineSegmentContainer = styled.div`
  width: 100%;
  height: 100px;
  position: relative;
`;

const NarrowScreenFinalLineSegmentLeft = styled.div`
  height: 50%;
  width: 40%;
  border-left: ${theme.colors.green1} solid 4px;
  border-bottom: ${theme.colors.green1} solid 4px;
  border-radius: 0 10px;
  position: absolute;
  top: 0;
  left: 10px;
`;

const NarrowScreenFinalLineSegmentRight = styled.div`
  height: 55%;
  width: 15%;
  border-right: ${theme.colors.green1} solid 4px;
  border-top: ${theme.colors.green1} solid 4px;
  border-radius: 0 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-100%, -4px);
`;

const ContentContainer = styled.div<{ column?: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};
  justify-content: center;
  gap: ${(props) => (props.column ? '0' : '40px')};
  width: 100%;
  position: relative;
`;

const TextContainer = styled.div<{ align?: string; border?: boolean }>`
  width: 100%;
  max-width: ${theme.space.measure};
  margin-bottom: 20px;
  text-align: ${(props) => props.align};
  border: ${(props) => (props.border ? ` 3px solid ${theme.colors.green1}` : 'none')};
  border-radius: 8px;
  padding: ${(props) => (props.border ? '8px' : '0')};
`;
